import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ActionPartial,
  ClassnamePartial,
  EnvironmentPartial,
  QaIdPartial,
  CodeSnippet,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Card = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Card;
    return <Component {...props} />;
  },
});

const CardMedia = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.CardMedia;
    return <Component {...props} />;
  },
});

const Subhead = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Subhead;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/card.example');
const snippet2 = require('raw-loader!../../../data/snippets/card-media.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Card"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/layout-card--docs"
      />
      <CodeSnippet
        scope={{ React: React, Card: Card, Subhead: Subhead, Text: Text }}
        code={snippet}
        platform="react"
        gitHubLink="card"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>The main body content of the card.</Text>
          </PropListItem>

          <ClassnamePartial componentName="card" />

          <EnvironmentPartial />

          <ActionPartial
            isFooter
            showText
            showIcon
            showStatus
            showHref
            showTarget
            showTooltip
            showQaid
            showDisabled
            showOnClick
          />

          <PropListItem name="header" types={['ReactNode']}>
            <Text>
              Shown at the top of the card. Can be any combination of elements,
              but note that spacing is taken care of for you.
            </Text>
          </PropListItem>

          <PropListItem name="media" types={['ReactNode']}>
            <Text>
              Shown at the top of the card above the header in full bleed. Can
              be any combination of elements, but note that spacing is taken
              care of for you. If you need any media in the body of your card,
              you can use the CardMedia component to handle the image filling
              properly like so:
            </Text>
            <CodeSnippet
              scope={{
                React: React,
                Card: Card,
                CardMedia: CardMedia,
                Subhead: Subhead,
                Text: Text,
              }}
              code={snippet2}
              platform="react"
            />
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onClick" types={['() => void']}>
            <Text>Called when the card is clicked on.</Text>
          </PropListItem>

          <PropListItem name="onHover" types={['() => void']}>
            <Text>
              Called when the card is hovered over. Useful for if you have
              something like a SelectMark that you want to show the hover state
              for as well. Note that hover styling is taken care of for you.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial
            componentName="card"
            passedDown={['header', 'body', 'footer']}
          />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Card"
      />
    </PlatformTab>
  );
};

export default WebTab;
