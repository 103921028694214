import React from 'react';
import { Environment, IconWarning } from '@hudl/uniform-ui-components';
import { Link } from '../../../components';

import styles from './V5Notice.module.scss';

const V5Notice = ({ componentName, storybookUrl, additionalContent, code }) => {
  const url = storybookUrl ?? 'https://uniform-web.storybook.hudltools.com/';

  return (
    <Environment environment="light">
      <div className={styles.container}>
        <div>
          <IconWarning size="large" />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>Version 5+</div>
          <div>
            If using Uniform Version 5 or later, please refer to our{' '}
            <Link href={url} type="article">
              Storybook docs
            </Link>{' '}
            for the {componentName} {code ? 'API' : 'documentation'} and examples. {additionalContent}
          </div>
        </div>
      </div>
    </Environment>
  );
};

export default V5Notice;
