import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
  AccessibilityLabelPartial,
  SpacePartial,
} from '../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/rn-card.example');
const snippet2 = require('raw-loader!../../../data/snippets/rn-card-media.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="card"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>The content that appears in the body of the card.</Text>
          </PropListItem>

          <PropListItem name="footer" types={['Action[]']}>
            <Text>
              Can only contain actions the user can take on the card. Each
              action has the following shape:
            </Text>
            <List type="unordered">
              <li>
                <code>text</code> (string) - the text describing the action
              </li>
              <li>
                <code>icon</code> (element) - a Uniform icon
              </li>
              <li>
                <code>isDisabled</code> (bool) - determines whether the action
                is disabled
              </li>
              <li>
                <code>onPress</code> (func) - responds to the action being
                pressed
              </li>
            </List>
          </PropListItem>

          <PropListItem name="header" types={['ReactNode']}>
            <Text>
              Shown at the top of the card. Can be any combination of elements,
              but note that spacing is taken care of for you.
            </Text>
          </PropListItem>

          <PropListItem name="isFullWidth" types={['boolean']}>
            <Text>Determines whether the card goes edge-to-edge.</Text>
          </PropListItem>

          <PropListItem name="media" types={['ReactNode']}>
            <Text>
              Shown at the top of the card above the header in full bleed. Can
              be any combination of elements, but note that spacing is taken
              care of for you. If you need any media in the body of your card,
              you can use the CardMedia component to handle the image filling
              properly like so:
            </Text>
            <CodeSnippet
              disableCodeEditing
              code={snippet2}
              platform="react-native"
            />
          </PropListItem>

          <SpacePartial />
        </PropList>

        <PropList header="Events">
          <PropListItem name="onPress" types={['() => void']}>
            <Text>Responds to the card press event.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="card" />

          <TestIdPartial componentName="card" />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from a{' '}
              <Link href="https://reactnative.dev/docs/touchableopacity">
                touchable opacity
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Card"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
